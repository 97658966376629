@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');
body {
  font-family: 'DM Sans', sans-serif;
}

option {
  color: black;
}

#header {
  padding-top: var(--safe-area-inset-top);
  padding-bottom: var(--safe-area-inset-bottom);
}

.grecaptcha-badge {
  visibility: hidden !important;
}
